.main-container {
}

.titles-btn-style {
  h3 {
    color: whitesmoke;
    margin: 0;
    margin-bottom: 5px;
    font-weight: 100;
  }
  h1 {
    color: whitesmoke;
    margin: 0;
    font-weight: 600;
    line-height: 40px;
  }
  button {
    color: whitesmoke;
    width: 180px;
    height: 50px;
    margin-top: 30px;
    border-color: whitesmoke;
    border-width: 2px;
    border-radius: 0px;
    &:hover {
      box-shadow: 0px 0px 5px white;
      background-color: rgba($color: #ffffff, $alpha: .1);
    }
  }
}
.first-grid {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  top: 0;
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    //transform: translate(-50%, -50%);
    z-index: -3;
  }
  .first-grid-text {
    position: relative;
    color: whitesmoke;
    max-width: 300px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-left: 170px;
    @extend .titles-btn-style;
  }
  .arrow {
    position: absolute;
    bottom: 50px;
    right: 50px;
    color: white;
    font-size: 35px;
    animation: moveDown 1.3s infinite alternate;
  }
  /* Definición de la animación */
  @keyframes moveDown {
    0% {
        top: 93vh; /* Inicia en la parte superior */
    }
    100% {
        top: 90vh; /* Se mueve hacia abajo */
    }
  }
}
.second-grid {
  height: 840px;
  overflow: hidden;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('../../../../static/img/back2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .second-grid-text {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-left: 170px;
    max-width: 600px;
    @extend .titles-btn-style;
  }
}
.third-grid {
  height: 840px;
  overflow: hidden;
  width: 100%;
  background-color: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
  position: relative;
  .video2-background {
    position: absolute;
    z-index: -20;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    //background-color: black;
    position: fixed;
    z-index: -5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .third-grid-text {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-left: 170px;
    max-width: 600px;
    z-index: 5;
    position: relative;
    @extend .titles-btn-style;
  }
}