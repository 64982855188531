.form-lines {
  display: flex;
  justify-content: left;
  margin: 15px 0px;
}

.contact-main-container {
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h3 {
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 85px;
    font-size: 25px;
    font-weight: 700;
    padding-top: 35px;
  }
  h4 {
    background-color: whitesmoke;
    color: rgba(0, 0, 0, 0.651);
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    font-size: 16px;
    font-weight: 700;
    padding-top: 5px;
  }
  .contact-form-container {
    background-color: whitesmoke;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50PX;
    padding-bottom: 90PX;
    .form-line {
      @extend .form-lines;
    }
    .submit-form-btn {
      padding-top: 15px;
      display: flex;
      justify-content: right;
      width: 560px;
      button {
        height: 40px;
        width: 150px;
      }
    }
  }
}