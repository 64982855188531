.knowus-main-container {
  width: 100%;
  background-color: whitesmoke;
  margin-top: 85px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .knowus-title {
    margin-top: 60px;
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.651);
  }
  .knowus-text {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 70px;
    width: 70%;
    font-size: 16px;
  }
}