.navbar-transparent {
  background-color: transparent;
}
.navbar-black {
  background-color: rgba($color: #000000, $alpha: .80);
  backdrop-filter: blur(8px);
}
.nav-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 85px;
  z-index: 11;
  position: fixed;
  top: 0;
  transition: 0.4s ease;
  .nav-bar-container {
    color: rgba(245, 245, 245, 0.932);
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    ul {
      list-style: none;
      display: inline-flex;
      margin: 0;
      align-items: center;
      li {
        a {
          color: rgba(245, 245, 245, 0.932);
          text-decoration: none;
          cursor: pointer;
          margin: 0px 20px;
          transition-duration: .5s;
          &:hover {
            color: rgb(255, 255, 255);
          }
        }
      }
    }
    button {
      color: whitesmoke;
      margin-left: 15px;
    }
    .login-btn {
      color: whitesmoke;
      width: 120px;
      border-color: whitesmoke;
      font-size: 12px;
      font-weight: 500;
      border-radius: 0px;
      &:hover {
        box-shadow: 0px 0px 5px white;
        background-color: rgba($color: #ffffff, $alpha: .1);
      }
    }
  }
}
.main-container {
  height: auto;
  width: 100%;
  top: 0;
  position: relative;
  margin: 0;
  padding: 0;
}
.footer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  z-index: 0;
  background-color: black;
  color: white;
  .footer-container {
    width: 60%;
    margin-top: 10px;
    padding: 0;
    display: flexbox;
    .text {
      font-size: 10px;
      font-weight: 100;
    }
  }
}

.nav-search-drawer {
  backdrop-filter: blur(3px);
  z-index: 10;
  .nav-search-container {
    background-color: black;
    height: 100vh;
    width: 500px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-bar-form {
      width: 65%;
      margin-top: 40px;
      .search-field {
        width: 100%;
      }
    }
  }
}
