.auth-main-container {
  background-color: black;
  height: 100vh;
  width: 100%;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  .main-auth {
    width: 800px;
    height: 600px;
    display: flex;
    justify-content: space-between;
    .auth-form {
      display: flex;
      flex-direction: column;
      padding: 0px 10px;
      width: 47%;
      border: solid rgba($color: #fff, $alpha: .5) .1px;
      align-items: center;
    }
  }
}

.pass-reset-main-container {
  background-color: black;
  height: 100vh;
  width: 100%;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  .main-password-reset {
    display: flex;
    flex-direction: column;
    width: 100%;
    .password-reset-logo {
      border-bottom: solid rgba($color: #fff, $alpha: .3) .5px;
      height: 60px;
      align-items: center;
      display: flex;
      margin-left: 10%;
    }
    .reset-password-form {
      width: 400px;
      margin: auto;
      margin-top: 60px;
      border-radius: 2px;
      background-color: #ededed;
    }
  }
}

.create-user-main-container {
  background-color: black;
  height: 100vh;
  width: 100%;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  .main-create-user {
    display: flex;
    flex-direction: column;
    width: 100%;
    .create-user-logo {
      border-bottom: solid rgba($color: #fff, $alpha: .3) .5px;
      height: 60px;
      align-items: center;
      display: flex;
      margin-left: 10%;
    }
    .create-user-form {
      width: 400px;
      margin: auto;
      margin-top: 60px;
      border-radius: 2px;
      background-color: #ededed;
    }
  }
}