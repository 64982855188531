.plans-main-container {
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .cards-container {
    margin: auto;
    margin-top: 97px;
    display: flex;
    .card {
      max-width: 320px;
      margin: 0px 10px;
      .img {
        height: 330px;
      }
    }
  }
  .other-plans {
    display: flex;
    color: whitesmoke;
    margin: auto;
    margin-top: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .other-card {
      max-width: 720px;
      margin-top: 30px;
      .contact-btn {
        width: 250px;
        margin: auto;
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        color: black;
        border-color: black;
        border-radius: 0;
        transition-duration: .5s;
        height: 33px;
        &:hover {
          box-shadow: 0px 0px 1px black;
        }
      }
    }
  }
}