.products-main-container {
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  h3 {
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 85px;
    font-size: 25px;
    font-weight: 700;
    padding-top: 35px;
    padding-bottom: 35px;
  }
}